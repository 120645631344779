import './App.css';
import { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import SideMenu from './components/SideMenu';
import Login from './pages/Login';
import Users from './pages/Users';
import UserDetail from './pages/UserDetail';
import NotFound from './pages/NotFound';
import Teams from './pages/Teams';
import Matches from './pages/Matches';
import Stadiums from './pages/Stadiums';
import CreateStadium from './pages/CreateStadium';
import CreateTeam from './pages/CreateTeam';
import CreateMatch from './pages/CreateMatch';
import EditStadium from './pages/EditStadium';
import EditTeam from './pages/EditTeam';
import OTP from './pages/OTP';
import Push from './pages/Push';
import Fixture from './pages/Fixtures';
import CreateFixture from './pages/CreateFixture';

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: false,
      role: localStorage.getItem('role'),
    };
  }

  componentDidMount() {
    const isAuthenticated = localStorage.getItem('isAuthenticated');
    if (isAuthenticated) {
      this.setState({ isAuthenticated: true });
    }
  }

  render() {
    return (
      <main className="flex flex-row">
        {this.state.isAuthenticated ? <SideMenu /> : <></>}

        <div className='flex flex-col'>
          <div className='px-6 py-4 bg-white w-screen border-b'>
            <span className='text-sm text-gray-500'>Kombine Transferi</span>
            <h1 className='text-2xl font-bold pt-2'>
              {
                window.location.pathname === '/users' ? 'Kullanıcılar' :
                  window.location.pathname.includes('/users/') ? 'Kullanıcı Detayı' :
                    window.location.pathname === '/teams' ? 'Takımlar' :
                      window.location.pathname === '/teams/create' ? 'Takım Oluştur' :
                        window.location.pathname.includes('/teams/edit/') ? 'Takım Düzenle' :
                          window.location.pathname === '/matches' ? 'Maçlar' :
                            window.location.pathname === '/matches/create' ? 'Maç Oluştur' :
                              window.location.pathname === '/fixture' ? 'Fikstür' :
                                window.location.pathname === '/fixture/create' ? 'Fikstür Oluştur' :
                                  window.location.pathname === '/stadiums' ? 'Stadyumlar' :
                                    window.location.pathname === '/stadiums/create' ? 'Stadyum Oluştur' :
                                      window.location.pathname.includes('/stadiums/edit/') ? 'Stadyum Düzenle' :
                                        window.location.pathname === '/' ? 'Giriş Yap' :
                                          window.location.pathname === '/otp' ? 'OTP' :
                                            window.location.pathname === '/push' ? 'Bildirim Gönder' :
                                              '404' // default
              }
            </h1>
          </div>

          <Router>
            <Switch>
              <Route path='/users' exact component={Users} />
              <Route path='/users/:id' exact component={UserDetail} />

              <Route path='/teams' exact component={Teams} />
              <Route path='/teams/create' exact component={CreateTeam} />
              <Route path='/teams/edit/:id' exact component={EditTeam} />

              <Route path='/matches' exact component={Matches} />
              <Route path='/matches/create' exact component={CreateMatch} />

              <Route path='/fixture' exact component={Fixture} />
              <Route path='/fixture/create' exact component={CreateFixture} />

              <Route path='/stadiums' exact component={Stadiums} />
              <Route path='/stadiums/create' exact component={CreateStadium} />
              <Route path='/stadiums/edit/:id' exact component={EditStadium} />

              <Route path='/push' exact component={Push} />

              <Route path='/' exact component={Login} />
              <Route path='/otp' exact component={OTP} />
              <Route path='*' exact component={NotFound} />
            </Switch>
          </Router>
        </div>
      </main >
    );
  }
}

