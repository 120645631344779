import { Component } from 'react';
import Logo from '../assets/black-logo.svg';
import { verifyOTP } from '../services/service';

export default class OTP extends Component {
    constructor(props) {
        super(props);
        this.state = {
            otp: new Array(6).fill(''),
        };
    }

    handleChange = (element, index) => {
        if (isNaN(element.value)) return;

        this.setState(prevState => {
            const newOtp = [...prevState.otp];
            newOtp[index] = element.value;
            return { otp: newOtp };
        });

        if (element.nextSibling && element.value) {
            element.nextSibling.focus();
        }
    };

    handleKeyDown = (event, index) => {
        if (event.key === 'Backspace') {
            this.setState(prevState => {
                const newOtp = [...prevState.otp];
                newOtp[index] = '';
                return { otp: newOtp };
            });

            if (event.target.previousSibling) {
                event.target.previousSibling.focus();
            }
        }
    };

    handleVerify = () => {
        alert(`Entered OTP: ${this.state.otp.join('')}`);
    };

    componentDidMount() {
        document.title = 'Kombine Transferi | OTP';
    }

    async verify() {
        const otp = this.state.otp.join('');
        const email = localStorage.getItem('email');

        if (!otp) {
            alert('Please fill all the fields');
            return;
        }

        this.setState({ loading: true });

        const data =
            await verifyOTP({ otp: otp, email: email });

        if (data.error) {
            alert(data.error);
            this.setState({ loading: false });
            return;
        }

        if (data.data) {
            localStorage.setItem('isAuthenticated', true);
            window.location.href = '/users';
        }
    }

    render() {
        return (
            <div className='p-8 w-full'>
                <div className='flex flex-col items-center justify-center py-12 sm:px-6 lg:px-8'>
                    <div className='w-full max-w-sm'>
                        <img className='mx-auto h-24 w-auto' src={Logo} alt='KT' />
                        <div className='mt-16'>
                            <div className='flex justify-between'>
                                {this.state.otp.map((data, index) => (
                                    <input
                                        className='appearance-none relative block w-12 h-12 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 text-center rounded-md focus:outline-none focus:ring-red-500 focus:border-red-500 focus:z-10 sm:text-sm mb-4'
                                        type='text'
                                        name='otp'
                                        maxLength='1'
                                        key={index}
                                        placeholder='0'
                                        value={data}
                                        onChange={e => this.handleChange(e.target, index)}
                                        onFocus={e => e.target.select()}
                                        onKeyDown={e => this.handleKeyDown(e, index)}
                                    />
                                ))}
                            </div>

                            <button
                                onClick={() => this.verify()}
                                className='group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'>
                                Doğrula
                            </button>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}