import { Component } from "react";
import { getMatches, updateMatch, } from "../services/service";

export default class Matches extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            matches: [],
            showDateModal: false,
            date: '',
            selectedMatch: null,
            sortConfig: {
                key: null,
                direction: 'asc'
            }
        };
    }

    handleSort = (key) => {
        let direction = 'asc';
        if (this.state.sortConfig.key === key && this.state.sortConfig.direction === 'asc') {
            direction = 'desc';
        }

        const sortedMatches = [...this.state.matches].sort((a, b) => {
            const aValue = this.getNestedValue(a, key);
            const bValue = this.getNestedValue(b, key);

            if (aValue < bValue) {
                return direction === 'asc' ? -1 : 1;
            }
            if (aValue > bValue) {
                return direction === 'asc' ? 1 : -1;
            }
            return 0;
        });

        this.setState({
            matches: sortedMatches,
            sortConfig: { key, direction }
        });
    }

    getNestedValue = (object, key) => {
        return key.split('.').reduce((obj, property) => obj ? obj[property] : null, object);
    }

    fetchMatches = async () => {
        const { data } = await getMatches();
        this.setState({ matches: data, loading: false });
    }

    updateDate = async () => {
        const { data } = await updateMatch({ id: this.state.selectedMatch._id, date: this.state.date });
        if (data) {
            this.setState({ showDateModal: false, date: '' });
            this.fetchMatches();
        }
    }

    componentDidMount() {
        this.fetchMatches();
    }

    render() {
        const { loading, matches, showDateModal, sortConfig } = this.state;

        const getArrow = (key) => {
            if (sortConfig.key === key) {
                return sortConfig.direction === 'asc' ? ' ▲' : ' ▼';
            }
            return '';
        };


        return (
            <main className='px-8 py-8 flex flex-col w-fit'>
                {
                    showDateModal && (
                        <div className='fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center'>
                            <div className='bg-white p-8 rounded-lg shadow-lg'>
                                <h2 className='text-xl font-bold mb-4'>Maç Tarihini Değiştir</h2>
                                <input
                                    type='datetime-local'
                                    value={this.state.date}
                                    onChange={(e) => this.setState({ date: e.target.value })}
                                    className='border border-slate-200 p-2 w-full mb-4' />
                                <div className='flex items-center justify-end gap-x-4'>
                                    <button
                                        onClick={this.updateDate}
                                        className='bg-black text-white px-4 py-2 rounded-md'>
                                        Kaydet
                                    </button>
                                    <button
                                        onClick={() => this.setState({ showDateModal: false })}
                                        className=' text-black px-4 py-2 rounded-md'>
                                        İptal
                                    </button>
                                </div>
                            </div>
                        </div>
                    )
                }

                {
                    loading ? (
                        <div className='flex items-center justify-center h-32'>
                            <span className='text-lg font-bold'>Yükleniyor...</span>
                        </div>
                    ) : (
                        <div className='bg-white rounded-xl pt-4'>
                            <div className='flex items-center justify-between mb-4'>
                                <span className="px-4 font-semibold">Maç Listesi</span>
                                <a
                                    href='/matches/create'
                                    className='bg-red-500 text-white px-2 py-1.5 mr-4 rounded-md text-sm'>
                                    Yeni Ekle
                                </a>
                            </div>
                            <table className="mt-4">
                                <thead className='bg-gray-100 text-gray-600'>
                                    <tr>
                                        <th className='border-b border-slate-200 p-4 text-left text-sm font-bold hover:cursor-pointer' onClick={() => this.handleSort('home_team.name')}>
                                            Ev Sahibi{getArrow('home_team')}
                                        </th>
                                        <th className='border-b border-slate-200 p-4 text-left text-sm font-bold hover:cursor-pointer' onClick={() => this.handleSort('away_team.name')}>
                                            Deplasman{getArrow('away_team.name')}
                                        </th>
                                        <th className='border-b border-slate-200 p-4 text-left text-sm font-bold hover:cursor-pointer' onClick={() => this.handleSort('home_team.stadium.name')}>
                                            Stadyum{getArrow('home_team.stadium.name')}
                                        </th>
                                        <th className='border-b border-slate-200 p-4 text-left text-sm font-bold hover:cursor-pointer' onClick={() => this.handleSort('date')}>
                                            Maç Tarihi{getArrow('date')}
                                        </th>
                                        <th className='border-b border-slate-200 p-4 text-left text-sm font-bold hover:cursor-pointer' onClick={() => this.handleSort('created_at')}>
                                            Kayıt Tarihi{getArrow('created_at')}
                                        </th>
                                        <th className='border-b border-slate-200 p-4 text-left text-sm font-bold'></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {matches.map((match) => (
                                        <tr key={match._id}>
                                            <td className='border-b border-slate-200 p-4 text-left text-sm'>
                                                <div className='flex items-center gap-x-2'>
                                                    <img src={match.home_team.logo} alt={match.home_team.name} className='w-8 h-8 mr-2 object-cover' />
                                                    {match.home_team.name}
                                                </div>
                                            </td>
                                            <td className='border-b border-slate-200 p-4 text-left text-sm'>
                                                <div className='flex items-center gap-x-2'>
                                                    {match.away_team.name}
                                                    <img src={match.away_team.logo} alt={match.away_team.name} className='w-8 h-8 mr-2 object-cover' />
                                                </div>
                                            </td>
                                            <td className='border-b border-slate-200 p-4 text-left text-sm'>
                                                {match.home_team.stadium.name}
                                            </td>
                                            <td className='border-b border-slate-200 p-4 text-left text-sm'>{
                                                new Date(match.date).toLocaleDateString('tr-TR', {
                                                    day: 'numeric',
                                                    month: 'long',
                                                    hour: 'numeric',
                                                    minute: 'numeric',
                                                })}
                                            </td>
                                            <td className='border-b border-slate-200 p-4 text-left text-sm'>{
                                                new Date(match.created_at).toLocaleDateString('tr-TR', {
                                                    day: 'numeric',
                                                    month: 'long',
                                                    hour: 'numeric',
                                                    minute: 'numeric',
                                                })}

                                            </td>
                                            <td className='border-b border-slate-200 p-4 text-left text-sm'>
                                                <button
                                                    onClick={() => this.setState({ showDateModal: true, selectedMatch: match })}
                                                    className='bg-gray-100 text-black border px-2 py-1.5 rounded-md'>
                                                    Tarihi Değiştir
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )
                }
            </main>
        );
    };
}