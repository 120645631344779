import { Component } from "react";
import { addMatch, getTeams } from "../services/service";

export default class CreateMatch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            teams: [],
            homeTeam: '',
            awayTeam: '',
            date: '',
        };
    }

    create = async () => {
        if (!this.state.homeTeam) {
            alert('Ev Sahibi takım seçmelisiniz');
            return;
        }

        if (!this.state.awayTeam) {
            alert('Deplasman takım seçmelisiniz');
            return;
        }

        if (this.state.homeTeam === this.state.awayTeam) {
            alert('Ev Sahibi ve Deplasman takım aynı olamaz');
            return;
        }

        if (!this.state.date) {
            alert('Maç tarihi seçmelisiniz');
            return;
        }

        await addMatch({
            homeTeam: this.state.homeTeam,
            awayTeam: this.state.awayTeam,
            date: this.state.date,
        });

        window.location.href = '/matches';
    }

    fetchTeams = async () => {
        const { data } = await getTeams();
        this.setState({ teams: data, loading: false });
    };

    componentDidMount() {
        this.fetchTeams();
    }

    render() {
        const { teams, homeTeam, awayTeam, date } = this.state;

        return (
            <main className='px-8 py-8 flex flex-col w-fit'>
                <section className='flex flex-col p-8 bg-white rounded-lg'>
                    <div className='mb-4'>
                        <label htmlFor='homeTeam' className='block text-sm font-bold mb-2'>Ev Sahibi</label>
                        <select
                            id='homeTeam'
                            className='w-full border border-slate-200 rounded-md p-2'
                            value={homeTeam}
                            onChange={(e) => this.setState({ homeTeam: e.target.value })}>
                            <option value=''>Ev Sahibi Seçiniz</option>
                            {teams.map((team) => (
                                <option key={team._id} value={team._id}>{team.name}</option>
                            ))}
                        </select>
                    </div>

                    <div className='mb-4'>
                        <label htmlFor='awayTeam' className='block text-sm font-bold mb-2'>Deplasman</label>
                        <select
                            id='awayTeam'
                            className='w-full border border-slate-200 rounded-md p-2'
                            value={awayTeam}
                            onChange={(e) => this.setState({ awayTeam: e.target.value })}>
                            <option value=''>Deplasman Seçiniz</option>
                            {teams.map((team) => (
                                <option key={team._id} value={team._id}>{team.name}</option>
                            ))}
                        </select>
                    </div>

                    <div className='mb-4'>
                        <label htmlFor='date' className='block text-sm font-bold mb-2'>Maç Tarihi</label>
                        <input
                            type='datetime-local'
                            id='date'
                            className='w-full border border-slate-200 rounded-md p-2'
                            value={date}
                            onChange={(e) => this.setState({ date: e.target.value })} />
                    </div>

                    <button
                        className='bg-black text-white px-4 py-2 rounded-md'
                        onClick={this.create}>
                        Kaydet
                    </button>
                </section>
            </main>
        );
    };
}