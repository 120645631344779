import { Component } from "react";
import { addStadium } from "../services/service";

export default class CreateStadium extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            blocks: [],
            seatingPlan: '',
            txtBlock: '',
        };
    }

    create = async () => {
        if (!this.state.name) {
            alert('Stadyum adı boş olamaz');
            return;
        }

        if (!this.state.blocks.length) {
            alert('Blok eklemelisiniz');
            return;
        }

        if (!this.state.seatingPlan) {
            alert('Koltuk planı eklemelisiniz');
            return;
        }

        await addStadium({
            name: this.state.name,
            blocks: this.state.blocks,
            seating_plan: this.state.seatingPlan,
        });

        window.location.href = '/stadiums';
    };

    render() {
        return (
            <main className='px-8 py-8 flex flex-col w-fit'>
                <section className='flex flex-col bg-white p-8 rounded-lg'>
                    <div className='mb-4'>
                        <label htmlFor='name' className='block text-sm font-bold mb-2'>Stadyum Adı</label>
                        <input
                            type='text'
                            id='name'
                            name='name'
                            value={this.state.name}
                            onChange={(e) => this.setState({ name: e.target.value })}
                            className='w-full border border-slate-200 rounded-md p-2'
                        />
                    </div>

                    <div className='mb-4'>
                        <label htmlFor='blocks' className='block text-sm font-bold mb-2'>Blok</label>
                        <div className='flex flex-col'>
                            <input
                                type='text'
                                id='blocks'
                                name='blocks'
                                value={this.state.txtBlock}
                                onChange={(e) => this.setState({ txtBlock: e.target.value })}
                                className='w-full border border-slate-200 rounded-md p-2'
                            />

                            <button
                                type='button'
                                onClick={() => {
                                    if (!this.state.txtBlock) {
                                        alert('Blok adı boş olamaz');
                                        return;
                                    }

                                    this.setState({
                                        blocks: [...this.state.blocks, this.state.txtBlock],
                                        txtBlock: '',
                                    });
                                }}
                                className='bg-gray-100 border text-black px-2 py-1.5 rounded-md mt-2'>
                                Ekle
                            </button>
                        </div>
                    </div>

                    <ul>
                        {this.state.blocks.map((block, index) => (
                            <div key={index} className="flex flex-row justify-between col-span-full xl:col-span-8 bg-white shadow-sm rounded-lg border border-slate-200 w-full px-4 py-2 mt-2">
                                <li className='mr-2'>{block}</li>
                                <button
                                    type='button'
                                    onClick={() => {
                                        const blocks = this.state.blocks.filter((_, i) => i !== index);
                                        this.setState({ blocks });
                                    }}
                                    className='text-red-500'>
                                    Sil
                                </button>
                            </div>
                        ))}
                    </ul>

                    <div className='my-4'>
                        <label htmlFor='seatingPlan' className='block text-sm font-bold mb-2'>Koltuk Planı</label>
                        <input
                            type='file'
                            accept='image/*'
                            onChange={e => this.setState({ seatingPlan: e.target.files[0] })}
                            className='w-full border border-slate-200 rounded-md p-2'
                        />
                    </div>

                    <button
                        onClick={this.create}
                        className='bg-black text-white px-2 py-1.5 rounded-md'>
                        Kaydet
                    </button>
                </section>

            </main>
        );
    };
}