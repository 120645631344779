import { Component } from "react";
import { deleteFixture, getFixtures, updateFixture } from "../services/service";

export default class Fixture extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            selectedFixture: null,
            showDateModal: false,
            fixture: [],
            startDate: '',
            endDate: '',
        };
    }

    updateDate = async () => {
        const { data } = await updateFixture({ startDate: this.state.startDate, endDate: this.state.endDate, id: this.state.selectedFixture._id });
        if (data) {
            this.setState({ showDateModal: false, startDate: '', endDate: '', selectedFixture: null });
            this.fetchFixture();
        }
    }

    delete = async () => {
        await deleteFixture({ id: this.state.selectedFixture._id });
        this.fetchFixture();

    }

    fetchFixture = async () => {
        const { data } = await getFixtures();
        this.setState({ fixture: data, loading: false });
    };

    componentDidMount() {
        this.fetchFixture();
    }

    render() {
        const { loading, fixture, showDateModal, startDate, endDate } = this.state;
        return (
            <main className='px-8 py-8 flex flex-col w-fit'>
                {
                    showDateModal && (
                        <div className='fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center'>
                            <div className='bg-white p-8 rounded-lg shadow-lg'>
                                <h2 className='text-xl font-bold mb-4'>Maç Tarihini Değiştir</h2>
                                <input
                                    type='date'
                                    value={startDate}
                                    onChange={(e) => this.setState({ startDate: e.target.value })}
                                    className='border border-slate-200 p-2 w-full mb-4' />
                                <input
                                    type='date'
                                    value={endDate}
                                    onChange={(e) => this.setState({ endDate: e.target.value })}
                                    className='border border-slate-200 p-2 w-full mb-4' />

                                <div className='flex items-center justify-end gap-x-4'>
                                    <button
                                        onClick={this.updateDate}
                                        className='bg-black text-white px-4 py-2 rounded-md'>
                                        Kaydet
                                    </button>
                                    <button
                                        onClick={() => this.setState({ showDateModal: false })}
                                        className=' text-black px-4 py-2 rounded-md'>
                                        İptal
                                    </button>
                                </div>
                            </div>
                        </div>
                    )
                }

                {
                    loading ? (
                        <div className='flex items-center justify-center h-32'>
                            <span className='text-lg font-bold'>Yükleniyor...</span>
                        </div>
                    ) : (
                        <div className='bg-white rounded-xl pt-4'>
                            <div className='flex items-center justify-between mb-4'>
                                <span className="px-4 font-semibold">Fikstür</span>
                                <a
                                    href='/fixture/create'
                                    className='bg-red-500 text-white px-2 py-1.5 mr-4 rounded-md text-sm'>
                                    Yeni Ekle
                                </a>
                            </div>
                            <table className="mt-4">
                                <thead className='bg-gray-100 text-gray-600'>
                                    <tr>
                                        <th className='border-b border-slate-200 p-4 text-left text-sm font-bold'>Başlık</th>
                                        <th className='border-b border-slate-200 p-4 text-left text-sm font-bold'>Başlama Tarihi</th>
                                        <th className='border-b border-slate-200 p-4 text-left text-sm font-bold'>Bitiş Tarihi</th>
                                        <th className='border-b border-slate-200 p-4 text-left text-sm font-bold'></th>
                                        <th className='border-b border-slate-200 p-4 text-left text-sm font-bold'></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {fixture.map((f) => (
                                        <tr key={f._id}>
                                            <td className='border-b border-slate-200 p-4 text-left text-sm'>{f.title}</td>
                                            <td className='border-b border-slate-200 p-4 text-left text-sm'>{
                                                new Date(f.start_date).toLocaleDateString('tr-TR', {
                                                    day: 'numeric',
                                                    month: 'long',
                                                    hour: 'numeric',
                                                    minute: 'numeric',
                                                })}
                                            </td>

                                            <td className='border-b border-slate-200 p-4 text-left text-sm'>{
                                                new Date(f.end_date).toLocaleDateString('tr-TR', {
                                                    day: 'numeric',
                                                    month: 'long',
                                                    hour: 'numeric',
                                                    minute: 'numeric',
                                                })}
                                            </td>

                                            <td className='border-b border-slate-200 p-4 text-left text-sm'>
                                                <button
                                                    onClick={() => this.setState({ showDateModal: true, selectedFixture: f })}
                                                    className='bg-gray-100 text-black border px-2 py-1.5 rounded-md'>
                                                    Düzenle
                                                </button>
                                            </td>

                                            <td className='border-b border-slate-200 p-4 text-left text-sm'>
                                                <button
                                                    onClick={() => this.setState({ selectedFixture: f }, this.delete)}
                                                    className='bg-red-500 text-white border px-2 py-1.5 rounded-md'>
                                                    Sil
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )
                }
            </main >
        );
    };
}