import { Component } from 'react';
import { signInWithEmail } from '../services/auth';
import Logo from '../assets/black-logo.svg';
import { sendOTP } from '../services/service';

export default class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            isAuthenticated: false,
        };
    }

    componentDidMount() {
        document.title = 'Kombine Transferi | Giriş yap';

        if (localStorage.getItem('isAuthenticated')) {
            window.location.href = '/users';
        }
    }

    async login() {
        const { email, password } = this.state;

        if (!email || !password) {
            alert('Please fill all the fields');
            return;
        }

        this.setState({ loading: true });

        const [, error] = await signInWithEmail(email, password);

        this.setState({ loading: false });

        if (error) {
            switch (error.code) {
                case "auth/invalid-email":
                    alert("Geçersiz e-posta adresi.");
                    break;
                case "auth/user-disabled":
                    alert("Kullanıcı hesabı devre dışı bırakıldı.");
                    break;
                case "auth/user-not-found":
                    alert("Kullanıcı bulunamadı.");
                    break;
                case "auth/wrong-password":
                    alert("Yanlış şifre.");
                    break;
                default:
                    alert("Bir hata oluştu.");
                    break;
            }

            return;
        }

        await sendOTP({ email: email });
        localStorage.setItem('email', email);
        window.location.href = '/otp';
    }

    render() {
        const { isAuthenticated } = this.state;
        return (
            !isAuthenticated ?
                <div className='p-8 w-full'>
                    <div className='flex flex-col items-center justify-center py-12 sm:px-6 lg:px-8'>
                        <div className='w-full max-w-sm'>
                            <img className='mx-auto h-24 w-auto' src={Logo} alt='KT' />
                            <div className='mt-16'>
                                <input
                                    value={this.state.email}
                                    onChange={(e) => this.setState({ email: e.target.value })}
                                    id='email-address' name='email' type='email' autoComplete='email' required className='appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-red-500 focus:border-red-500 focus:z-10 sm:text-sm mb-4' placeholder='E-posta' />

                                <input
                                    value={this.state.password}
                                    onChange={(e) => this.setState({ password: e.target.value })}
                                    id='password' name='password' type='password' autoComplete='current-password' required className='appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-red-500 focus:border-red-500 focus:z-10 sm:text-sm mb-8' placeholder='Şifre' />

                                <button
                                    onClick={() => this.login()}
                                    className='group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'>
                                    Giriş yap
                                </button>

                            </div>
                        </div>
                    </div>
                </div>
                : null
        );
    }
}