import { Component } from "react";
import { createFixture } from "../services/service";

export default class CreateFixture extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            title: '',
            startDate: '',
            endDate: '',
        };
    }

    create = async () => {
        if (!this.state.startDate) {
            alert('Başlangıç tarihi seçmelisiniz');
            return;
        }

        if (!this.state.endDate) {
            alert('Bitiş tarihi seçmelisiniz');
            return;
        }

        if (this.state.startDate > this.state.endDate) {
            alert('Başlangıç tarihi bitiş tarihinden büyük olamaz');
            return;
        }

        if (this.state.startDate === this.state.endDate) {
            alert('Başlangıç tarihi bitiş tarihinden farklı olmalıdır');
            return;
        }

        await createFixture({
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            title: this.state.title,
        });

        window.location.href = '/fixture';
    }

    render() {
        const { startDate, endDate } = this.state;

        return (
            <main className='px-8 py-8 flex flex-col w-fit'>
                <section className='flex flex-col p-8 bg-white rounded-lg'>
                    <div className='mb-4'>
                        <label htmlFor='date' className='block text-sm font-bold mb-2'>Başlık</label>
                        <input
                            type='text'
                            id='title'
                            className='w-full border border-slate-200 rounded-md p-2'
                            value={this.state.title}
                            onChange={(e) => this.setState({ title: e.target.value })} />
                    </div>

                    <div className='mb-4'>
                        <label htmlFor='date' className='block text-sm font-bold mb-2'>Başlangıç Tarihi</label>
                        <input
                            type='date'
                            id='date'
                            className='w-full border border-slate-200 rounded-md p-2'
                            value={startDate}
                            onChange={(e) => this.setState({ startDate: e.target.value })} />
                    </div>

                    <div className='mb-4'>
                        <label htmlFor='date' className='block text-sm font-bold mb-2'>Bitiş Tarihi</label>
                        <input
                            id='date'
                            type='date'
                            className='w-full border border-slate-200 rounded-md p-2'
                            value={endDate}
                            onChange={(e) => this.setState({ endDate: e.target.value })} />
                    </div>

                    <button
                        className='bg-black text-white px-4 py-2 rounded-md'
                        onClick={this.create}>
                        Kaydet
                    </button>
                </section>
            </main>
        );
    };
}