import { Component } from "react";
import { push } from "../services/service";

export default class Push extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            title: '',
            body: '',
        };
    }

    create = async () => {
        if (!this.state.title) {
            alert('Başlık boş olamaz');
            return;
        }

        if (!this.state.body) {
            alert('İçerik boş olamaz');
            return;
        }

        await push({
            title: this.state.title,
            body: this.state.body
        });

        window.location.href = '/';
    }

    render() {
        const { title, body } = this.state;
        return (
            <main className='px-8 py-8 flex flex-col w-fit'>
                <section className='flex flex-col p-8 bg-white rounded-lg'>
                    <div className='mb-4'>
                        <label htmlFor='name' className='block text-sm font-bold mb-2'>Başlık</label>
                        <input
                            type='text'
                            id='name'
                            name='name'
                            value={title}
                            onChange={(e) => this.setState({ title: e.target.value })}
                            className='w-full border border-slate-200 rounded-md p-2'
                        />
                    </div>

                    <div className='mb-4'>
                        <label htmlFor='name' className='block text-sm font-bold mb-2'>İçerik</label>
                        <input
                            type='text'
                            id='name'
                            name='name'
                            value={body}
                            onChange={(e) => this.setState({ body: e.target.value })}
                            className='w-full border border-slate-200 rounded-md p-2'
                        />
                    </div>

                    <button
                        onClick={this.create}
                        className='bg-black text-white px-2 py-1.5 rounded-md'>
                        Gönder
                    </button>
                </section>
            </main>
        );
    };
}