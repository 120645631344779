import { Component } from "react";
import { getUsers } from "../services/service";
import dayjs from "dayjs";

export default class Users extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            users: [],
            sortConfig: {
                key: null,
                direction: 'asc'
            }
        };
    }

    handleSort = (key) => {
        let direction = 'asc';
        if (this.state.sortConfig.key === key && this.state.sortConfig.direction === 'asc') {
            direction = 'desc';
        }

        const sortedUsers = [...this.state.users].sort((a, b) => {
            if (a[key] < b[key]) {
                return direction === 'asc' ? -1 : 1;
            }
            if (a[key] > b[key]) {
                return direction === 'asc' ? 1 : -1;
            }
            return 0;
        });

        this.setState({
            users: sortedUsers,
            sortConfig: { key, direction }
        });
    }

    fetchUsers = async () => {
        const { data } = await getUsers();
        this.setState({ users: data, loading: false });
    };

    componentDidMount() {
        this.fetchUsers();
    }

    render() {
        const { loading, users, sortConfig } = this.state;

        const getArrow = (key) => {
            if (sortConfig.key === key) {
                return sortConfig.direction === 'asc' ? ' ▲' : ' ▼';
            }
            return '';
        };


        return (
            <main className='px-8 py-8 flex flex-col w-fit'>
                {
                    loading ? (
                        <div className='flex items-center justify-center h-32'>
                            <span className='text-lg font-bold'>Yükleniyor...</span>
                        </div>
                    ) : (
                        <div className='bg-white rounded-xl pt-4'>
                            <span className="px-4 font-semibold">Kullanıcı Listesi</span>
                            <table className="mt-4">
                                <thead className='bg-gray-100 text-gray-600'>
                                    <tr>
                                        <th className='border-b border-slate-200 p-4 text-left text-sm font-bold hover:cursor-pointer' onClick={() => this.handleSort('name')}>
                                            Ad Soyad{getArrow('name')}
                                        </th>
                                        <th className='border-b border-slate-200 p-4 text-left text-sm font-bold hover:cursor-pointer' onClick={() => this.handleSort('email')}>
                                            E-posta{getArrow('email')}
                                        </th>
                                        <th className='border-b border-slate-200 p-4 text-left text-sm font-bold hover:cursor-pointer' onClick={() => this.handleSort('phone')}>
                                            Telefon{getArrow('phone')}
                                        </th>
                                        <th className='border-b border-slate-200 p-4 text-left text-sm font-bold hover:cursor-pointer' onClick={() => this.handleSort('is_verified')}>
                                            Doğrulama{getArrow('is_verified')}
                                        </th>
                                        <th className='border-b border-slate-200 p-4 text-left text-sm font-bold hover:cursor-pointer' onClick={() => this.handleSort('active_plan')}>
                                            Üyelik{getArrow('active_plan')}
                                        </th>
                                        <th className='border-b border-slate-200 p-4 text-left text-sm font-bold hover:cursor-pointer' onClick={() => this.handleSort('is_disabled')}>
                                            Durum{getArrow('is_disabled')}
                                        </th>
                                        <th className='border-b border-slate-200 p-4 text-left text-sm font-bold hover:cursor-pointer' onClick={() => this.handleSort('updated_at')}>
                                            Son Aktivite{getArrow('updated_at')}
                                        </th>
                                        <th className='border-b border-slate-200 p-4 text-left text-sm font-bold hover:cursor-pointer'></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {users.map((user) => (
                                        <tr key={user._id}>
                                            <td className='border-b border-slate-200 p-4 text-left text-sm'>
                                                {user.name}
                                            </td>
                                            <td className='border-b border-slate-200 p-4 text-left text-sm'>
                                                {user.email}
                                            </td>
                                            <td className='border-b border-slate-200 p-4 text-left text-sm'>
                                                {user.phone || '-'}
                                            </td>
                                            <td className='border-b border-slate-200 p-4 text-center text-sm'>
                                                {
                                                    user.is_verified ?
                                                        <div className='bg-green-50 text-green-600 border border-green-300 w-min px-4 py-1.5 rounded-full text-xs'>
                                                            Evet
                                                        </div> :
                                                        <div className='bg-gray-50 text-gray-600 border border-gray-200 w-min px-4 py-1.5 rounded-full text-xs'>
                                                            Hayır
                                                        </div>
                                                }
                                            </td>
                                            <td className='border-b border-slate-200 p-4 text-left text-sm'>
                                                {user.active_plan.toUpperCase()}
                                            </td>
                                            <td className='border-b border-slate-200 p-4 text-left text-sm'>
                                                {
                                                    user.is_disabled || user.is_deleted ?

                                                        <div className='bg-red-50 text-red-600 border border-red-300 w-min px-4 py-1.5 rounded-full text-xs'>
                                                            Kapalı
                                                        </div> :
                                                        <div className='bg-green-50 text-green-600 border border-green-300 w-min px-4 py-1.5 rounded-full text-xs'>
                                                            Aktif
                                                        </div>
                                                }
                                            </td>
                                            <td className='border-b border-slate-200 p-4 text-left text-sm'>{
                                                dayjs(user.updated_at).format('DD/MM/YYYY HH:mm')
                                            }
                                            </td>
                                            <td className='border-b border-slate-200 p-4 text-left text-sm'>
                                                <a
                                                    href={`/users/${user._id}`}
                                                    className='bg-gray-100 text-black border px-2 py-1.5 rounded-md'>
                                                    Göster
                                                </a>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )
                }
            </main>
        );
    };
}