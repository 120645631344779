import { Component } from "react";
import { getStadiumDetail, updateStadium } from "../services/service";

export default class EditStadium extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            stadium: null,
            name: '',
            seatingPlan: '',
            fileSeatingPlan: null,
            blocks: [],
            txtBlock: '',
        };
    }

    editStadium = async () => {
        const { name, seatingPlan, blocks, fileSeatingPlan } = this.state;

        await updateStadium({
            id: this.props.match.params.id,
            name,
            seatingPlan,
            fileSeatingPlan,
            blocks,
        });

        window.location.href = '/stadiums';
    };

    fetchStadium = async () => {
        const { data } = await getStadiumDetail({ id: this.props.match.params.id });

        this.setState({
            stadium: data,
            name: data.name,
            seatingPlan: data.seating_plan,
            blocks: data.blocks,
            loading: false
        });
    };

    componentDidMount() {
        this.fetchStadium();
    }

    render() {
        const { loading, stadium } = this.state;
        return (
            <main className='px-8 py-8 flex flex-col w-fit'>
                <section className="p-8 col-span-full xl:col-span-8 bg-white shadow-sm rounded-lg border border-slate-200 w-full">
                    {
                        loading ? (
                            <div className='flex items-center justify-center h-32'>
                                <span className='text-lg font-bold'>Yükleniyor...</span>
                            </div>
                        ) : (
                            <>
                                <div className='mb-4'>
                                    <label htmlFor='name' className='block text-sm font-bold mb-2'>Stadyum Adı</label>
                                    <input
                                        type='text'
                                        id='name'
                                        name='name'
                                        value={this.state.name}
                                        onChange={(e) => this.setState({ name: e.target.value })}
                                        className='w-full border border-slate-200 rounded-md p-2'
                                    />
                                </div>

                                <div className='mb-4'>
                                    <label htmlFor='blocks' className='block text-sm font-bold mb-2'>Blok</label>
                                    <div className='flex flex-col'>
                                        <input
                                            type='text'
                                            id='blocks'
                                            name='blocks'
                                            value={this.state.txtBlock}
                                            onChange={(e) => this.setState({ txtBlock: e.target.value })}
                                            className='w-full border border-slate-200 rounded-md p-2'
                                        />

                                        <button
                                            type='button'
                                            onClick={() => {
                                                if (!this.state.txtBlock) {
                                                    alert('Blok adı boş olamaz');
                                                    return;
                                                }

                                                this.setState({
                                                    blocks: [...this.state.blocks, this.state.txtBlock],
                                                    txtBlock: '',
                                                });
                                            }}
                                            className='bg-gray-100 border text-black px-2 py-1.5 rounded-md mt-2'>
                                            Ekle
                                        </button>
                                    </div>
                                </div>

                                <ul>
                                    {this.state.blocks.map((block, index) => (
                                        <div key={index} className="flex flex-row justify-between col-span-full xl:col-span-8 bg-white shadow-sm rounded-lg border border-slate-200 w-full px-4 py-2 mt-2">
                                            <li className='mr-2'>{block}</li>
                                            <button
                                                type='button'
                                                onClick={() => {
                                                    const blocks = this.state.blocks.filter((_, i) => i !== index);
                                                    this.setState({ blocks });
                                                }}
                                                className='text-red-500'>
                                                Sil
                                            </button>
                                        </div>
                                    ))}
                                </ul>

                                <div className='my-4'>
                                    <div className="flex flex-row justify-between">
                                        <label htmlFor='seatingPlan' className='block text-sm font-bold mb-2'>Koltuk Planı</label>
                                        <a href={stadium.seating_plan} target='_blank' rel='noreferrer' className='text-sm text-black-500 underline'>Göster</a>
                                    </div>
                                    <input
                                        type='file'
                                        accept='image/*'
                                        onChange={e => this.setState({ fileSeatingPlan: e.target.files[0] })}
                                        className='w-full border border-slate-200 rounded-md p-2'
                                    />
                                </div>

                                <button
                                    onClick={this.editStadium}
                                    className='bg-black text-white px-2 py-1.5 rounded-md w-full'>
                                    Kaydet
                                </button>
                            </>
                        )
                    }
                </section>
            </main>
        );
    };
}