import { Component } from "react";
import { addTeam, getStadiums } from "../services/service";

export default class CreateTeam extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            stadiums: [],
            selectedStadium: '',
            logo: '',
            name: '',
            isTemporary: '',
        };
    }

    create = async () => {
        if (!this.state.name) {
            alert('Takım adı boş olamaz');
            return;
        }

        if (!this.state.selectedStadium) {
            alert('Stadyum seçmelisiniz');
            return;
        }

        if (!this.state.logo) {
            alert('Logo seçmelisiniz');
            return;
        }


        await addTeam({
            name: this.state.name,
            logo: this.state.logo,
            stadium: this.state.selectedStadium,
            is_temporary: this.state.isTemporary === 'true' ? true : false
        });

        window.location.href = '/teams';
    }

    fetchStadiums = async () => {
        const { data } = await getStadiums();
        this.setState({ stadiums: data, loading: false });
    };

    componentDidMount() {
        this.fetchStadiums();
    }

    render() {
        const { stadiums, name } = this.state;
        return (
            <main className='px-8 py-8 flex flex-col w-fit'>
                <section className='flex flex-col p-8 bg-white rounded-lg'>
                    <div className='mb-4'>
                        <label htmlFor='name' className='block text-sm font-bold mb-2'>Takım Adı</label>
                        <input
                            type='text'
                            id='name'
                            name='name'
                            value={name}
                            onChange={(e) => this.setState({ name: e.target.value })}
                            className='w-full border border-slate-200 rounded-md p-2'
                        />
                    </div>

                    <div className='mb-4'>
                        <label htmlFor='seatingPlan' className='block text-sm font-bold mb-2'>Takım Logosu</label>
                        <input
                            type='file'
                            accept='image/*'
                            onChange={e => this.setState({ logo: e.target.files[0] })}
                            className='w-full border border-slate-200 rounded-md p-2'
                        />
                    </div>

                    <div className='mb-4'>
                        <label htmlFor='seatingPlan' className='block text-sm font-bold mb-2'>Stadyum</label>
                        <select
                            value={this.state.selectedStadium}
                            onChange={(e) => this.setState({ selectedStadium: e.target.value })}
                            className='w-full border border-slate-200 rounded-md p-2'>
                            <option value='' disabled>Stadyum Seçiniz</option>
                            {
                                stadiums.map((stadium) => (
                                    <option key={stadium._id} value={stadium._id}>{stadium.name}</option>
                                ))
                            }
                        </select>
                    </div>

                    <div className='mb-4'>
                        <label htmlFor='isTemporary' className='block text-sm font-bold mb-2'>Geçici Takım mı?</label>
                        <select
                            value={this.state.isTemporary}
                            onChange={(e) => this.setState({ isTemporary: e.target.value })}
                            className='w-full border border-slate-200 rounded-md p-2'>
                            <option value='' disabled>Seçiniz</option>
                            <option value='true'>Evet</option>
                            <option value='false'>Hayır</option>
                        </select>
                    </div>

                    <button
                        onClick={this.create}
                        className='bg-black text-white px-2 py-1.5 rounded-md'>
                        Kaydet
                    </button>
                </section>

            </main>
        );
    };
}