import { getFirestore, collection, query, orderBy, getDocs } from 'firebase/firestore';
import { app } from './firebase-config';
import { storage } from './firebase-config';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import imageCompression from 'browser-image-compression';

const db = getFirestore(app);

export const uploadImage = async (file) => {
    const options = {
        maxSizeMB: .5,
        maxWidthOrHeight: 500,
        useWebWorker: true
    }

    const compressedFile = await imageCompression(file, options);

    const storageRef = ref(storage, `${Date.now()}`);
    var snapshot = await uploadBytesResumable(storageRef, compressedFile, { contentType: 'image/png' });
    return getDownloadURL(snapshot.ref);
}

export const getUserChats = async ({ id }) => {
    try {
        const response = await fetch(
            `https://api.kombinetransferi.com/chat/list`,
            {
                method: 'POST',
                body: JSON.stringify({ user: id }),
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        );

        const data = await response.json();

        return {
            error: null,
            data: data.data,
            status: response.status,
        };
    } catch (error) {
        return { error, status: error.response?.status };
    }
};

export const getUserReports = async ({ id }) => {
    try {
        const response = await fetch(
            `https://api.kombinetransferi.com/admin/list-reports`,
            {
                method: 'POST',
                body: JSON.stringify({ reported: id }),
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        );

        const data = await response.json();

        return {
            error: null,
            data: data.data,
            status: response.status,
        };
    } catch (error) {
        return { error, status: error.response?.status };
    }
};

export const getChatMessages = async ({ chatId }) => {
    const q = query(collection(db, 'chats', chatId, 'messages'), orderBy('message_at'));
    const querySnapshot = await getDocs(q);

    var messages = [];

    querySnapshot.forEach((doc) => {
        messages.push({ id: doc.id, ...doc.data() });
    });

    console.log(messages);
    return {
        error: null,
        data: messages,
        status: 200,
    };
};

export const getUsers = async () => {
    try {
        const response = await fetch(
            'https://api.kombinetransferi.com/admin/list-users',
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        );

        const data = await response.json();

        return {
            error: null,
            data: data.data,
            status: response.status,
        };
    } catch (error) {
        return { error, status: error.response?.status };
    }
};

export const getUserDetail = async ({ id }) => {
    try {
        const response = await fetch(
            `https://api.kombinetransferi.com/admin/get-user`,
            {
                method: 'POST',
                body: JSON.stringify({ id }),
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        );

        const data = await response.json();

        return {
            error: null,
            data: data.data,
            status: response.status,
        };
    } catch (error) {
        return { error, status: error.response?.status };
    }
};

export const getStadiums = async () => {
    try {
        const response = await fetch(
            'https://api.kombinetransferi.com/stadium/list',
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        );

        const data = await response.json();

        return {
            error: null,
            data: data.data,
            status: response.status,
        };
    } catch (error) {
        return { error, status: error.response?.status };
    }
};

export const getFixtures = async () => {
    try {
        const response = await fetch(
            'https://api.kombinetransferi.com/admin/list-all-fixtures',
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        );

        const data = await response.json();

        return {
            error: null,
            data: data.data,
            status: response.status,
        };
    } catch (error) {
        return { error, status: error.response?.status };
    }
};

export const updateTeam = async ({ id, name, logo, stadium, is_temporary }) => {
    try {
        var url = logo;

        if (logo instanceof File) {
            url = await uploadImage(logo);
        }

        const response = await fetch(
            `https://api.kombinetransferi.com/team/update`,
            {
                method: 'POST',
                body: JSON.stringify({ id, name, logo: url, stadium, is_temporary }),
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        );

        const data = await response.json();

        return {
            error: null,
            data: data.data,
            status: response.status,
        };
    } catch (error) {
        return { error, status: error.response?.status };
    }
};

export const getStadiumDetail = async ({ id }) => {
    try {
        const response = await fetch(
            `https://api.kombinetransferi.com/stadium/get`,
            {
                method: 'POST',
                body: JSON.stringify({ id }),
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        );

        const data = await response.json();

        return {
            error: null,
            data: data.data,
            status: response.status,
        };
    } catch (error) {
        return { error, status: error.response?.status };
    }
};

export const updateStadium = async ({ id, name, seatingPlan, fileSeatingPlan, blocks }) => {
    try {
        var url = seatingPlan;

        if (fileSeatingPlan) {
            url = await uploadImage(fileSeatingPlan);
        }

        const response = await fetch(
            `https://api.kombinetransferi.com/stadium/update`,
            {
                method: 'POST',
                body: JSON.stringify({ id, name, seating_plan: url, blocks }),
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        );

        const data = await response.json();

        return {
            error: null,
            data: data.data,
            status: response.status,
        };
    } catch (error) {
        return { error, status: error.response?.status };
    }
};

export const addStadium = async ({ name, blocks, seating_plan }) => {
    try {
        var url = await uploadImage(seating_plan);

        const response = await fetch(
            `https://api.kombinetransferi.com/admin/create-stadium`,
            {
                method: 'POST',
                body: JSON.stringify({ name, blocks, seating_plan: url }),
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        );

        const data = await response.json();

        return {
            error: null,
            data: data.data,
            status: response.status,
        };
    } catch (error) {
        return { error, status: error.response?.status };
    }
};

export const getTeams = async () => {
    try {
        const response = await fetch(
            'https://api.kombinetransferi.com/team/list-all',
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        );

        const data = await response.json();

        return {
            error: null,
            data: data.data,
            status: response.status,
        };
    } catch (error) {
        return { error, status: error.response?.status };
    }
};

export const getTeamDetail = async ({ id }) => {
    try {
        const response = await fetch(
            `https://api.kombinetransferi.com/team/get`,
            {
                method: 'POST',
                body: JSON.stringify({ id }),
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        );

        const data = await response.json();

        return {
            error: null,
            data: data.data,
            status: response.status,
        };
    } catch (error) {
        return { error, status: error.response?.status };
    }
}

export const addTeam = async ({ name, logo, stadium, is_temporary }) => {
    try {
        var url = await uploadImage(logo);

        const response = await fetch(
            `https://api.kombinetransferi.com/admin/create-team`,
            {
                method: 'POST',
                body: JSON.stringify({ name, logo: url, stadium, is_temporary }),
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        );

        const data = await response.json();

        return {
            error: null,
            data: data.data,
            status: response.status,
        };
    } catch (error) {
        return { error, status: error.response?.status };
    }
};

export const push = async ({ title, body }) => {
    try {
        const response = await fetch(
            `https://api.kombinetransferi.com/admin/push-notification-all`,
            {
                method: 'POST',
                body: JSON.stringify({ title, body }),
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        );

        const data = await response.json();

        return {
            error: null,
            data: data.data,
            status: response.status,
        };
    } catch (error) {
        return { error, status: error.response?.status };
    }
};

export const getMatches = async () => {
    try {
        const response = await fetch(
            'https://api.kombinetransferi.com/match/list',
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        );

        const data = await response.json();

        return {
            error: null,
            data: data.data,
            status: response.status,
        };
    } catch (error) {
        return { error, status: error.response?.status };
    }
};

export const updateMatch = async ({ id, date }) => {
    try {
        date = new Date(date).toISOString();

        const response = await fetch(
            `https://api.kombinetransferi.com/match/update`,
            {
                method: 'POST',
                body: JSON.stringify({ id, date: date }),
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        );

        const data = await response.json();

        return {
            error: null,
            data: data.data,
            status: response.status,
        };
    } catch (error) {
        return { error, status: error.response?.status };
    }
};

export const addMatch = async ({ homeTeam, awayTeam, date }) => {
    try {
        date = new Date(date).toISOString();

        const response = await fetch(
            `https://api.kombinetransferi.com/admin/create-match`,
            {
                method: 'POST',
                body: JSON.stringify({ home_team: homeTeam, away_team: awayTeam, date }),
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        );

        const data = await response.json();

        return {
            error: null,
            data: data.data,
            status: response.status,
        };
    } catch (error) {
        return { error, status: error.response?.status };
    }
};

export const disableUser = async ({ id, reason }) => {
    try {
        const response = await fetch(
            `https://api.kombinetransferi.com/admin/ban-user`,
            {
                method: 'POST',
                body: JSON.stringify({ id, reason }),
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        );

        const data = await response.json();

        return {
            error: null,
            data: data.data,
            status: response.status,
        };
    } catch (error) {
        return { error, status: error.response?.status };
    }
};

export const activateUser = async ({ id }) => {
    try {
        const response = await fetch(
            `https://api.kombinetransferi.com/admin/activate-user`,
            {
                method: 'POST',
                body: JSON.stringify({ id }),
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        );

        const data = await response.json();

        return {
            error: null,
            data: data.data,
            status: response.status,
        };
    } catch (error) {
        return { error, status: error.response?.status };
    }
};

export const sendOTP = async ({ email }) => {
    try {
        const response = await fetch(
            `https://api.kombinetransferi.com/admin/send-otp`,
            {
                method: 'POST',
                body: JSON.stringify({ email }),
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        );

        const data = await response.json();

        return {
            error: null,
            data: data.data,
            status: response.status,
        };
    } catch (error) {
        return { error, status: error.response?.status };
    }
};

export const verifyOTP = async ({ otp, email }) => {
    try {
        const response = await fetch(
            `https://api.kombinetransferi.com/admin/verify-otp`,
            {
                method: 'POST',
                body: JSON.stringify({
                    otp,
                    email
                }),
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        );

        const data = await response.json();

        return {
            error: data.error,
            data: data.data,
            status: response.status,
        };
    } catch (error) {
        return { error, status: error.response?.status };
    }
};

export const createFixture = async ({ startDate, endDate, title }) => {
    try {
        startDate = new Date(startDate).toISOString();
        endDate = new Date(endDate).toISOString();

        const response = await fetch(
            `https://api.kombinetransferi.com/admin/create-fixture`,
            {
                method: 'POST',
                body: JSON.stringify({ start_date: startDate, end_date: endDate, title: title }),
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        );

        const data = await response.json();

        return {
            error: null,
            data: data.data,
            status: response.status,
        };
    } catch (error) {
        return { error, status: error.response?.status };
    }
};

export const updateFixture = async ({ startDate, endDate, id }) => {
    try {
        startDate = new Date(startDate).toISOString();
        endDate = new Date(endDate).toISOString();

        const response = await fetch(
            `https://api.kombinetransferi.com/admin/update-fixture`,
            {
                method: 'POST',
                body: JSON.stringify({ start_date: startDate, end_date: endDate, id }),
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        );

        const data = await response.json();

        return {
            error: null,
            data: data.data,
            status: response.status,
        };
    } catch (error) {
        return { error, status: error.response?.status };
    }
};


export const deleteFixture = async ({ id }) => {
    try {
        const response = await fetch(
            `https://api.kombinetransferi.com/admin/delete-fixture`,
            {
                method: 'POST',
                body: JSON.stringify({ id }),
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        );

        const data = await response.json();

        return {
            error: null,
            data: data.data,
            status: response.status,
        };
    } catch (error) {
        return { error, status: error.response?.status };
    }
};