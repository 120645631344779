import { Component } from "react";
import { getStadiums } from "../services/service";

export default class Stadiums extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            stadiums: [],
        };
    }

    fetchStadiums = async () => {
        const { data } = await getStadiums();
        this.setState({ stadiums: data, loading: false });
    };

    componentDidMount() {
        this.fetchStadiums();
    }

    render() {
        const { loading, stadiums } = this.state;
        return (
            <main className='px-8 py-8 flex flex-col w-fit'>
                {
                    loading ? (
                        <div className='flex items-center justify-center h-32'>
                            <span className='text-lg font-bold'>Yükleniyor...</span>
                        </div>
                    ) : (
                        <div className='bg-white rounded-xl pt-4'>
                            <div className='flex items-center justify-between mb-4'>
                                <span className="px-4 font-semibold">Stadyum Listesi</span>
                                <a
                                    href='/stadiums/create'
                                    className='bg-red-500 text-white px-2 py-1.5 mr-4 rounded-md text-sm'>
                                    Yeni Ekle
                                </a>
                            </div>
                            <table className="mt-4">
                                <thead className='bg-gray-100 text-gray-600'>
                                    <tr>
                                        <th className='border-b border-slate-200 p-4 text-left text-sm font-bold'>Adı</th>
                                        <th className='border-b border-slate-200 p-4 text-left text-sm font-bold'>Bloklar</th>
                                        <th className='border-b border-slate-200 p-4 text-left text-sm font-bold'>Oturma Planı</th>
                                        <th className='border-b border-slate-200 p-4 text-left text-sm font-bold'>Kayıt Tarihi</th>
                                        <th className='border-b border-slate-200 p-4 text-left text-sm font-bold'></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {stadiums.map((stadium) => (
                                        <tr key={stadium._id}>
                                            <td className='border-b border-slate-200 p-4 text-left text-sm'>
                                                {stadium.name}
                                            </td>

                                            <td className='border-b border-slate-200 p-4 text-left text-sm'>
                                                <select>
                                                    {stadium.blocks.map((block) => (
                                                        <option key={block} value={block}>{block}</option>
                                                    ))}
                                                </select>
                                            </td>
                                            <td className='border-b border-slate-200 p-4 text-left text-sm'>
                                                <a href={stadium.seating_plan} className='text-black underline' target="_blank" rel="noreferrer">
                                                    Göster
                                                </a>
                                            </td>
                                            <td className='border-b border-slate-200 p-4 text-left text-sm'>{
                                                new Date(stadium.created_at).toLocaleDateString('tr-TR', {
                                                    day: 'numeric',
                                                    month: 'long',
                                                    hour: 'numeric',
                                                    minute: 'numeric',
                                                })}
                                            </td>

                                            <td className='border-b border-slate-200 p-4 text-left text-sm'>
                                                <a
                                                    href={`/stadiums/edit/${stadium._id}`}
                                                    className='bg-gray-100 text-black border px-2 py-1.5 rounded-md'>
                                                    Düzenle
                                                </a>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )
                }
            </main >
        );
    };
}