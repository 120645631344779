// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyBR-ErogcyV75RMQyGs415tWaWLoM0FSQo",
    authDomain: "kombine-transferi.firebaseapp.com",
    projectId: "kombine-transferi",
    storageBucket: "kombine-transferi.appspot.com",
    messagingSenderId: "641768933436",
    appId: "1:641768933436:web:c9d54f6cb26d18dedf9b39",
    measurementId: "G-S11Z78RSYB"
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const analytics = getAnalytics(app);
const auth = getAuth(app);

export { app, analytics, storage, auth };