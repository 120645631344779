import { People, Building, UserOctagon, PlayCircle, Logout, Notification, Calendar } from 'iconsax-react';
import { Component } from 'react';
import logo from '../assets/logo.png';
import { signOut } from '../services/auth';

export default class SideMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMenuOpen: true,
        };
    }

    toggleMenu = () => {
        this.setState({ isMenuOpen: !this.state.isMenuOpen });
    }

    async logout() {
        await signOut();
        localStorage.removeItem('isAuthenticated');
        window.location.href = '/';
    }

    render() {
        const { isMenuOpen } = this.state;
        return (
            <main className='flex flex-row'>
                {
                    isMenuOpen && (
                        <aside className='w-60 bg-white px-4 min-h-screen border-r'>
                            <img src={logo} alt='logo' className='h-16 mx-auto my-4' />

                            <ul className='space-y-3 mt-8'>
                                <li>
                                    <a href='/users' className='flex items-center p-2 text-base font-normal text-black rounded-lg hover:bg-slate-100 hover:text-black'>
                                        <UserOctagon className='w-6 h-6 text-black' />
                                        <span className='ml-3'>Kullanıcılar</span>
                                    </a>
                                </li>
                                <li>
                                    <a href='/stadiums' className='flex items-center p-2 text-base font-normal text-black rounded-lg hover:bg-slate-100 hover:text-black'>
                                        <Building className='w-6 h-6 text-black' />
                                        <span className='flex-1 ml-3 whitespace-nowrap'>Stadyumlar</span>
                                    </a>
                                </li>
                                <li>
                                    <a href='/teams' className='flex items-center p-2 text-base font-normal text-black rounded-lg hover:bg-slate-100 hover:text-black'>
                                        <People className='w-6 h-6 text-black' />
                                        <span className='flex-1 ml-3 whitespace-nowrap'>Takımlar</span>
                                    </a>
                                </li>
                                <li>
                                    <a href='/matches' className='flex items-center p-2 mb-4 text-base font-normal text-black rounded-lg hover:bg-slate-100 hover:text-black'>
                                        <PlayCircle className='w-6 h-6 text-black' />
                                        <span className='flex-1 ml-3 whitespace-nowrap'>Maçlar</span>
                                    </a>
                                </li>
                                <li>
                                    <a href='/fixture' className='flex items-center p-2 mb-4 text-base font-normal text-black rounded-lg hover:bg-slate-100 hover:text-black'>
                                        <Calendar className='w-6 h-6 text-black' />
                                        <span className='flex-1 ml-3 whitespace-nowrap'>Fikstür</span>
                                    </a>
                                </li>
                                <li>
                                    <a href='/push' className='flex items-center p-2 mb-4 text-base font-normal text-black rounded-lg hover:bg-slate-100 hover:text-black'>
                                        <Notification className='w-6 h-6 text-black' />
                                        <span className='flex-1 ml-3 whitespace-nowrap'>Bildirim Gönder</span>
                                    </a>
                                </li>
                                <hr />
                                <li>
                                    <button
                                        onClick={this.logout}
                                        className='flex items-center p-2 text-base font-normal text-black rounded-lg hover:bg-slate-100 hover:text-black'>
                                        <Logout className='w-6 h-6 text-black' />
                                        <span className='flex-1 ml-3 whitespace-nowrap'>Çıkış</span>
                                    </button>
                                </li>
                            </ul>
                        </aside>
                    )
                }
            </main>
        );
    };
}