import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "./firebase-config";

export const signInWithEmail = async (email, password) => {
    try {
        const credentials = await signInWithEmailAndPassword(auth, email, password);
        return [credentials, null];
    } catch (error) {
        return [null, error];
    }
}

export const signOut = async () => {
    try {
        await auth.signOut();
        return [true, null];
    } catch (error) {
        return [null, error];
    }
}
